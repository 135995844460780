import {
  CommentsDisabledOutlined, CommentOutlined, }    from '@mui/icons-material';
import {Stack, Avatar, ListItemText,
  ListItem, ListItemButton, ListItemAvatar,  }    from '@mui/material'

import { useEffect, useState }    from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import  InfiniteScroll          from 'react-infinite-scroll-component'

import dataProv                   from '../dataProvider'
import {MySnackBar, SnackbarInfo} from '../utils/UIUtils'
import {FabMenu}                  from '../utils/fabMenu'
import {FabNavigateBack}          from '../utils/route'
import {HostInfoFragment,
  HostBannerData}                 from '../line/hostInfoFragment'

type LineViewModel = {
  host: {
    avatar_src: string,
    group_name: string,
    group_desc: string,
  },

  line: {
    name:     string,
    desc:     string,
    thumbnail_src: string,
    url:      string,
    is_open:  string,
    id:       string,
  },
}

type Pagination = {
  currentPage:  number,
  itemTotal:    number,
  itemPerPage:  number,
}

export const HostHome = () =>{

  const [refreshCount,  setRefreshCount]  = useState(0)
  const [lineModels,    setLineModels]    = useState<LineViewModel[]>([])
  const [pagination,    setPagination]    = useState<Pagination>(
    {currentPage: 1, itemTotal: 1000, itemPerPage: 10,}
  )

  const [bannerData,  setBannerData] = useState<HostBannerData>()
  // const [userInfo,    setUserInfo] = useState<UserInfo>();

  const [, setIsLoading] = useState(false)

  const [showSnackBar,  setShowSnackBar]  = useState(false)
  const [snackBarInfo,  ]  = useState<SnackbarInfo>({
    message: "default msg", severity: 'success'
  })
  const navigate  = useNavigate();
  const location  = useLocation();
  const params    = useParams();

  const handleItemClicked=(slot: LineViewModel)=>{
    navigate(slot.line.url, {state: {}})
  }

  useEffect(()=>{
    setBannerData(location.state as HostBannerData)
    const {hostId} = params;
    if( !hostId ){
      return
    }
    new Promise(async()=>{

      setIsLoading(true)
      await fetchMoreData(hostId)
      setIsLoading(false)

    }).catch((e)=>{
      setIsLoading(false)
    })

  },[refreshCount, params, location.state])

  const fetchLineModels = async (
    { page, perPage, hostId }: any
  ): Promise<[LineViewModel[], number]> =>{

    // const hostId = params.pathname?.split('/')[-1]
    // console.log({host_id})

    const linesResult = await dataProv.getList(
      'lines', {
        pagination: {page:page, perPage:perPage},
        filter:     {'user_id': hostId},
        sort:       {field:'create_time', order:'DESC'}
      }
    );

    const lineModelList: LineViewModel[] = []
    for(const line of linesResult.data){
      // const host = line.host

      lineModelList.push({
        host: {
          avatar_src: line.host.src,
          group_name: line.host.groupName,
          group_desc: line.host.groupDesc,
        },
        line: {
          name:     line.title,
          desc:     line.desc.slice(0,35) + '...',
          url:      `/line/${line.id}`,
          is_open:  line.is_open,
          id:       line.id,
          thumbnail_src: line.medias[0].src
        },
      })
    }
    return [lineModelList, linesResult.total]
  }

  const refresh = ()=>{
    setRefreshCount(refreshCount+1)
  }

  const  fetchMoreData = async (hostId: string) => {

    const [moreItems, total] = await fetchLineModels({
        page:     pagination.currentPage,
        perPage:  pagination.itemPerPage,
        hostId:   hostId,
      })
    setLineModels([...lineModels, ...moreItems])
    setPagination({
      ...pagination,
      currentPage:  pagination.currentPage+1,
      itemTotal:    total,
    })

    if( moreItems.length > 0 && !bannerData ){
      setBannerData({
        groupName: moreItems[0].host.group_name,
        groupDesc: moreItems[0].host.group_desc,
        avatarSrc: moreItems[0].host.avatar_src,
        hostId:    hostId
      })
    }
  }

  const hasMore = ()=>{
    // return pagination.itemPerPage * pagination.currentPage < pagination.itemTotal
    return lineModels.length < pagination.itemTotal
  }

  return (
    <main style={{  }}>
    <Stack justifyContent='center' alignItems='center'
          direction="column" spacing={1} paddingY='15px'>

      <HostInfoFragment {...bannerData} />
      <InfiniteScroll
          dataLength={lineModels.length}
          next={()=>{fetchMoreData(params.hostId??"...")}}
          hasMore={hasMore()}
          loader={<h4>Loading...</h4>}
        >
        {lineModels.map((lineModel) => {
          // const labelId = `checkbox-list-secondary-label-${slot.id}`;
          return (
            <ListItem
              key={lineModel.line.url}
              // secondaryAction={
              //   <Checkbox edge="end" // onChange={handleToggle(value)}
              //     checked={checked.indexOf(value) !== -1}
              //     inputProps={{ 'aria-labelledby': labelId }}
              //   />
              // }
              disablePadding
            >
              <ListItemButton onClick={()=>{handleItemClicked(lineModel)}}>
                <ListItemAvatar>
                  <Avatar
                    // alt={`Avatar n°${value + 1}`}
                    alt={lineModel.host.group_name}
                    src={lineModel.line.thumbnail_src}
                    // src={`/static/images/avatar/${value + 1}.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText
                    id={`${lineModel.line.id}-title`}
                    primary={lineModel.line.name}
                    secondary={lineModel.line.desc}
                />
                {
                  lineModel.line.is_open?
                    <CommentOutlined color='info'/>:
                    <CommentsDisabledOutlined color='disabled'/>
                }
              </ListItemButton>
            </ListItem>
          );
        })}

      </InfiniteScroll>

      </Stack>
      <FabMenu/>
      <FabNavigateBack/>
      <MySnackBar open={showSnackBar} setOpen={setShowSnackBar} info={snackBarInfo}/>
    </main>
  );
}
