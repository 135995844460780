import React, { useEffect, useState }    from 'react';
import {
  CommentsDisabledOutlined, CommentOutlined,  }    from '@mui/icons-material';
import {Stack, Avatar, ListItemText, Box, Typography,
  ListItem, ListItemButton, ListItemAvatar, TextField,
  styled, Popper, Button, ThemeProvider
}    from '@mui/material'

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import  InfiniteScroll          from 'react-infinite-scroll-component'
import Carousel                 from 'react-material-ui-carousel'

import dataProv                   from '../dataProvider'
import {MySnackBar, SnackbarInfo} from '../utils/UIUtils'
import {FabMenu}                  from '../utils/fabMenu'
import {FabNavigateBack}          from '../utils/route'
import {StoreInfoFragment}        from './StoreInfoFragment'

import { createTheme }            from '@mui/material/styles';

enum OrderType{
  SimpleNumber    = 'single-number',
  MultipleNumber  = 'multiple-number'
}

type SimpleNumberConfig = {
    deliveryFee: number,
    selfPickup: Boolean,
    showMOQ: Boolean,
    multiplesOf: number,
    unitName: string,
    unitPrice: number,
    moq:  number,
    maxq: number,
    addationalOptions: any[],
}

type OrderSchema = {
  type: OrderType,
  config: SimpleNumberConfig|any
}

type ProductDetailModel = {
  title:      string,
  desc:       string,
  medias:     any[],
  // url:      string,
  id:       string,
  orderType:  OrderSchema,
}

const theme = createTheme({
  // status: {
  //   danger: '#e53e3e',
  // },
  palette: {
    primary: {
      main: '#0971f1',
      // darker: '#053e85',
    },
    success: {
      main: '#07b150',
    }
    // neutral: {
    //   main: '#64748B',
    //   contrastText: '#fff',
    // },
  },
});

export const Product = () =>{

  const [refreshCount,  setRefreshCount]  = useState(0)
  const [viewModel,     setViewModel]    = useState<ProductDetailModel>()

  const params    = useParams();

  const {productId} = params;
  console.log(productId)

  useEffect(()=>{

    new Promise( async()=>{
      const result : any = await dataProv.getOne(
        'products', {
          id: productId
        }
      )

      console.log(result)
      setViewModel({
        id:         result.id,
        title:      result.data.title,
        desc:       result.data.desc,
        medias:     result.data.medias??[],
        orderType:  result.data.orderType,
      })


    })
  }, [])

  return (
    <main style={{  }}>
      <ThemeProvider theme={theme}>
      <Stack justifyContent='center' alignItems='center'
          direction="column" spacing={1} paddingY='15px'
          >
        <Typography variant='h1' sx={{
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          paddingTop: '15px',
          minWidth: { xs: 350, md: 250 },
        }}>
        <a href={`/product/${productId}`}>
          {viewModel?.title}
        </a>
        </Typography>
        {
        <Typography variant='body1' sx={{
          textAlign: 'center',
          paddingX: '25px',
          color: '#666666',
          minWidth: { xs: 350, md: 250 },
        }}>
          {viewModel?.desc}

        </Typography>
        }
        {
          // (viewModel !== undefined  && viewModel.medias.length>0) && (
          //   <Avatar alt="Host"
          //     src={viewModel.medias[0]?.src??""} style={{
          //     width: 96, height: 96, margin: 10,
          //   }} />
          // )
        }
        {
          // (viewModel !== undefined  && viewModel.medias.length>0) && (
          //   <Box component="img"
          //     sx={{
          //       height: {xs: 120, md: 120},
          //       minHeight: { xs: 120, md: 120 },
          //       maxWidth:   { xs: 120, md: 150},
          //       objectFit: 'contain',
          //       borderRadius: '5px',
          //       border: 2,
          //       borderColor: '#DDDDDD',
          //     }}
          //     alt="The house from the offer."
          //     src={viewModel.medias[0]?.src??""}
          //   />
          // )
        }
        {
          // ((viewModel?.medias?.length??0) > 1 )?
          // ((viewModel !== undefined  && viewModel.medias.length>0) && (
          //   <Box component="img"
          //     sx={{
          //       height: {xs: 120, md: 120},
          //       minHeight: { xs: 120, md: 120 },
          //       maxWidth:   { xs: 120, md: 150},
          //       objectFit: 'contain',
          //       borderRadius: '5px',
          //       border: 2,
          //       borderColor: '#DDDDDD',
          //     }}
          //     alt="The house from the offer."
          //     src={viewModel.medias[0]?.src??""}
          //   />
          // )):
          <Carousel sx={{
            width: '100%',
            height: '250px',
            justifyItems: 'center',
            alignContent: 'center',
            textAlign: 'center',
          }}>
            {
              viewModel?.medias?.map((media:any)=>{
                return (
                  <Box component="img"
                    sx={{
                      // height: {xs: 200, md: 220},
                      width: '85%',
                      height: '100%',
                      // minHeight: { xs: 220, md: 220 },
                      // maxWidth:   { xs: '320px', md: '320px'},
                      objectFit: 'contain',
                      borderRadius: '5px',
                      border: 2,
                      borderColor: '#DDDDDD',
                    }}
                    alt="The house from the offer."
                    src={media?.src??""}
                    key={media?.src}
                  />
                )
              })
            }
          </Carousel>
        }
        <Box height='10px'/>
        {viewModel &&
          <OrderPlacing orderType={viewModel?.orderType} />
        }
      </Stack>
      <FabMenu/>
      <FabNavigateBack/>
    </ThemeProvider>
    </main>
  );
}

const OrderPlacing = ({orderType}:{orderType:OrderSchema})=>{
  const [itemCount, setItemCount] = useState<number>()
  const [addationalOptions, setAddationalOptions] = useState<any[]>([])


  if( orderType.type == OrderType.SimpleNumber ){
    const config = orderType.config as SimpleNumberConfig

    const options = []
    for(let i = config.moq ; i <= config.maxq ; i=i+config.multiplesOf){
      options.push({
        label: `${i} ${config.unitName}s`,
        value: i,
      })
    }

    const calcOrderTotal = ()=>{

      return (itemCount??0) * config.unitPrice
    }

    return (
      <Stack direction="column" spacing='15px'>
        <Autocomplete
          disablePortal
          PopperComponent={StyledPopper}
          id="combo-box-demo"
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Number of slices: " />}
          onChange={
            (e:any, selected: any)=>{
              // console.log(e)
              // console.log(selected)
              // console.log(e.target)
              setItemCount(selected.value)
            }
          }
        />
        {
          (config.addationalOptions??[]).map(
            (ao: any)=>{
              return (
                <Autocomplete
                  disablePortal
                  PopperComponent={StyledPopper}
                  id={`ao-${ao.id}`}
                  options={ao.options.map((opt:any)=>({
                    label: opt.optionName,
                    value: opt.id
                  }))}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label={ao.name} />}
                />
              )
            }
          )
        }

        {
          // <Stack direction='row' justifyContent='center' paddingY='10px'>
          //   <Typography fontSize='18px'>
          //     Price : $50
          //   </Typography>
          // </Stack>
        }
        <Button size='large' variant='contained' color='success'
          sx={{
            width: '100%',
            height: '50px',
            paddingX: 0,
            mt: '2px',
          }}
          onClick={
            ()=>{
              // window.location.href = 'https://m.me/dona.manis.cake.shop'
              // window.location.href = 'fb-messenger://user-thread/247503785614409?intent_trigger=mme&source_id=1441792&nav=discover&tactic=interstitial_button_skipped_redirect'
            }
          }
          style={{textTransform: 'none'}} >
          <Stack direction='row' justifyContent='space-between'
            sx={{width: '100%'}}
            paddingX='15px' >
            <Typography color='white' fontSize='19px' fontWeight='500'
              textAlign='left' noWrap
              width='150px'>
              {`Cart · ${itemCount} ${config.unitName}`}
            </Typography>
            <Typography color='white' fontSize='19px' fontWeight='500'
              textAlign='right'
              width='80px'>
              {`S\$${calcOrderTotal()}`}
            </Typography>
          </Stack>
        </Button>
      </Stack>
    )

  }

  return null
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      // margin: 0,
    },
  },
});
